import { useAuthStore } from '~/store/auth'
import { useConveyancingCaseStore } from '~/store/conveyancing-case.js'

export default defineNuxtRouteMiddleware(async (to) => {
  const intendedRoute = useCookie('intendedRoute')

  if (process.server) return

  const authStore = useAuthStore()
  const conveyancingCaseStore = useConveyancingCaseStore()

  if (!authStore.isAuthenticated) {
    intendedRoute.value = to.fullPath

    return navigateTo('/login')
  } else {
    await authStore.fetchClient().catch(() => authStore.logout())

    if (to.path === '/') {
      switch (conveyancingCaseStore.conveyancingCase.matter_type_code) {
        case 'P':
          return navigateTo('/documents/purchase/first-letter')
        case 'S':
        case 'B':
        default:
          return navigateTo('/documents/sale/first-letter')
      }
    }
  }
})
